.App {
  max-width: 860px;
  margin: 40px auto;

}


button{
  background: none;
  border: 2px solid #fff;
  padding: 6px 12px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size:1em;
}

button:hover{
  background: #c23866;
  color: #fff
}

.card-grid{
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.square {
  background: rgba(27, 255, 255, 0.8);
  height: 5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 4rem;
}

.square:hover {
  background: rgb(27, 255, 255);
}

.square.clicked {
  background: rgb(63, 118, 170);
  cursor: not-allowed;
}

.board {
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
}

.end-game-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
}

.btn {
  background: rgb(27, 255, 255);
  color: rgb(63, 118, 170);
  border: 2px solid rgb(63, 118, 170);
  font-size: 2rem;
  margin-bottom: 1rem;
  padding: 10px 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s ease-out;
  max-height: 7rem;
}

.btn:hover {
  background: rgb(63, 118, 170);
  color: rgb(27, 255, 255);
  border: 2px solid rgb(27, 255, 255);
}

.win-text {
  color: white;
  font-size: 5rem;
}

.win-history {
  color: white;
  font-size: 2rem;
  margin: 2rem 0;
}